import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {Observable, throwError} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import {ReCaptchaV3Service} from "ng-recaptcha";
import {DialogService} from "./dialog.service";

enum requestTypes {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE'
}

type TOptions = { headers?: HttpHeaders };

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  headers: HttpHeaders;

  constructor(
    private _cookie: CookieService,
    private _http: HttpClient,
    private _dialog: DialogService,
    private _captcha: ReCaptchaV3Service
  ) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  private getAuthHeader(header: HttpHeaders): HttpHeaders {
    const token = this._cookie.get('access_token');
    if (token) {
      return header.append('Authorization', 'Bearer ' + token);
    }
    return header;
  }

  private getOptionsHeaders(options?: TOptions): HttpHeaders {
    return options && options.headers
      ? this.getAuthHeader(options.headers) : this.getAuthHeader(this.headers);
  }

  // Handle API errors
  handleError = (requestType: requestTypes, url: string) => (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`Error: ${requestType} ${url}`, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Error: ${requestType} ${url} ` +
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  get(url: string, options?: TOptions): Observable<any> {
    const requestOptions = {
      ...options,
      headers: this.getOptionsHeaders(options),
    };
    return this._http
      .get<any>(`${environment.baseUrl}${url}`, requestOptions)
      .pipe(catchError(this.handleError(requestTypes.get, url)));
  }

  post(url: string, body: any, options?: TOptions): Observable<any> {
    const requestOptions = {
      ...options,
      headers: this.getOptionsHeaders(options),
    };
    return this._http
      .post<any>(`${environment.baseUrl}${url}`, body, requestOptions)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError(requestTypes.post, url))
      );
  }

  put(url: string, body: any, options?: TOptions): Observable<any> {
    const requestOptions = {
      ...options,
      headers: this.getOptionsHeaders(options),
    };
    return this._http
      .put<any>(`${environment.baseUrl}${url}`, body, requestOptions)
      .pipe(catchError(this.handleError(requestTypes.put, url)));
  }

  patch(url: string, body: any, options?: TOptions): Observable<any> {
    const requestOptions = {
      ...options,
      headers: this.getOptionsHeaders(options),
    };
    return this._http
      .patch<any>(`${environment.baseUrl}${url}`, body, requestOptions)
      .pipe(catchError(this.handleError(requestTypes.patch, url)));
  }

  delete(url: string, options?: TOptions): Observable<any> {
    const requestOptions = {
      ...options,
      headers: this.getOptionsHeaders(options),
    };
    return this._http
      .delete<any>(`${environment.baseUrl}${url}`, requestOptions)
      .pipe(catchError(this.handleError(requestTypes.delete, url)));
  }

  captcha(): Observable<boolean> {
    return this._captcha.execute('importantAction').pipe(
      switchMap((token: string) => {
        return this.post('api/check-recaptcha', {token}).pipe(
          map(res => {
            if (!res) {
              this._dialog.alert({
                title: "Attention",
                message: "Oups. Quelque chose s'est mal passé..."
              });
            }
            return !!res;
          })
        )
      })
    );
  }
}
