<div class="footer-wrap">
  <div class="container">
    <div class="row gx-xxl-10">
      <div class="col-md-6 col-xl-2 mb-5 footer-logo col-container">
        <a href="/">
          <img src="assets/images/footer_logo.svg" alt="footer_logo">
        </a>
      </div>
      <div class="col-md-6 col-xl-4 mb-5 footer-nav col-container">
        <ul>
          <li *ngFor="let item of menu">
            <a href="#" (click)="$event.preventDefault(); goToSection(item.anchor)">
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-xl-3 mb-5 col-container">
        <div class="footer-field mb-5">
          <span class="field-label">Téléphone</span>
          <div class="field-phone-wrapper" >
            <a class="field-value" href="tel:+33758821864">
              +33 7 58 82 18 64
            </a>
            <div class="field-messenger">
              (whatsapp)
            </div>
          </div>
          <div class="field-phone-wrapper">
              <a class="field-value" href="tel:+380992242812">
                +38 0 99 22 42 812
              </a>
              <div class="field-messenger">
                (viber)
              </div>
          </div>
        </div>

        <div class="footer-field">
          <span class="field-label">france</span>
          <span class="field-value text-uppercase">
            France (Ile de France, Nantes, Etretat, Angers, Toulouse etc..)
          </span>
          <span class="field-value text-uppercase">
            Ukraine
          </span>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 mb-5 col-container">
        <div class="footer-field mb-5">
          <span class="field-label">Mail</span>
          <a class="field-value" href="mailto:travaux24.com@gmail.com">
            travaux24.com@gmail.com
          </a>
        </div>
        <div class="footer-field">
          <span class="field-label">Instagram</span>

          <a href="https://www.instagram.com/travaux24/" target="_blank" class="field-value menu-social">
            <img src="assets/images/icons/instagram.svg" alt="Instagram" />
            <div>TRAVAUX24</div>
          </a>
        </div>
        <div class="footer-field mt-3">
          <span class="field-label">Facebook</span>

          <a href="https://www.facebook.com/Travaux24/" target="_blank"
             class="field-value menu-social facebook-svg link-in-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.3 107.3">
              <defs>
                <style>
                  .cls-1,.cls-2{fill:#FFFFFF;}.cls-1{fill-rule:evenodd;}
                </style>
              </defs>
              <title>Facebook</title>
              <g id="Layer_2" data-name="Layer 2">
                <g >
                  <path class="cls-1" d="M45.29,53.9V85a.81.81,0,0,0,.81.81H57.65a.81.81,0,0,0,.81-.81V53.4h8.37a.82.82,0,0,0,.81-.74l.8-9.53a.8.8,0,0,0-.8-.87H58.46V35.5a2.87,2.87,0,0,1,2.87-2.87h6.45a.81.81,0,0,0,.81-.81V22.3a.81.81,0,0,0-.81-.81H56.88A11.59,11.59,0,0,0,45.29,33.08v9.18H39.52a.81.81,0,0,0-.81.81v9.52a.81.81,0,0,0,.81.81h5.77Z"/>
                  <path class="cls-2" d="M88.49,6.89a11.94,11.94,0,0,1,11.92,11.92V88.49a11.94,11.94,0,0,1-11.92,11.92H18.81A11.94,11.94,0,0,1,6.89,88.49V18.81A11.94,11.94,0,0,1,18.81,6.89H88.49m0-6.89H18.81A18.81,18.81,0,0,0,0,18.81V88.49A18.81,18.81,0,0,0,18.81,107.3H88.49A18.81,18.81,0,0,0,107.3,88.49V18.81A18.81,18.81,0,0,0,88.49,0Z"/>
                </g>
              </g>
            </svg>
            <div>TRAVAUX24</div>
          </a>
        </div>
        <div class="footer-field mt-3">
          <span class="field-label">LinkedIn</span>

          <a href="https://www.linkedin.com/in/gheorghe-sacaliuc-262804238/" target="_blank"
             class="field-value menu-social facebook-svg link-in-svg">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762
                0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966
                0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5
                12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </svg>
            <div>TRAVAUX24</div>
          </a>
        </div>
        <div class="footer-field mt-3">
          <span class="field-label">Twitter</span>

          <a href="https://twitter.com/travaux24" target="_blank"
             class="field-value menu-social facebook-svg link-in-svg">
            <svg viewBox="0 0 107.3 107.3">
              <defs>
                <style>.cls-1, .cls-2 {
                  fill: #27aae1;
                }
                .cls-2 {
                  fill-rule: evenodd;
                }</style>
              </defs>
              <g>
                <g>
                  <path class="cls-1" d="M84.68,34.07a25.61,25.61,0,0,1-6.31,1.81c1-.18,2.51-2,3.11-2.76a11.7,11.7,
                    0,0,0,2.11-3.83c0-.11.09-.25,0-.33a.32.32,0,0,0-.33,0,32.46,32.46,0,0,1-7.5,2.87.51.51,0,0,1-.52-.14,
                    5.24,5.24,0,0,0-.66-.67,13.3,13.3,0,0,0-3.63-2.23,12.66,12.66,0,0,0-5.58-.9,13.29,13.29,0,0,0-5.29,
                    1.5A13.65,13.65,0,0,0,55.8,32.9,13,13,0,0,0,53.25,38a13.92,13.92,0,0,0-.14,5.38c0,.3,0,.35-.25.3C42.55,
                    42.16,34,38.47,27.07,30.57c-.3-.34-.46-.34-.71,0-3,4.55-1.56,11.85,2.23,15.45.51.47,1,1,1.59,1.39a13.2,
                    13.2,0,0,1-5-1.39c-.3-.2-.45-.09-.48.26a8.25,8.25,0,0,0,.08,1.47A13.24,13.24,0,0,0,33,58.32a7.89,7.89,
                    0,0,0,1.65.51,14.79,14.79,0,0,1-4.88.15c-.35-.07-.49.11-.35.45C31.52,65.28,36.15,67,39.62,68c.47.09.93.09,
                    1.4.19,0,0-.06,0-.09.09-1.15,1.75-5.14,3.06-7,3.71A25.12,25.12,0,0,1,23.3,73.36c-.57-.08-.69-.08-.85,0s0,
                    .23.17.38c.72.48,1.46.9,2.21,1.31a35.21,35.21,0,0,0,7.11,2.85c12.77,3.53,27.16.93,36.76-8.6,7.53-7.48,
                    10.17-17.8,10.17-28.13,0-.41.48-.63.76-.85a24.58,24.58,0,0,0,5-5.2,1.49,1.49,0,0,0,.31-1v-.06C84.92,
                    33.91,84.94,34,84.68,34.07Z"/>
                  <path class="cls-2" d="M41,79.18Z"/>
                  <path class="cls-2" d="M41,79.18Z"/>
                  <path class="cls-2" d="M43.06,79.24c0-.11,1.11,0,0,0C43.06,79.18,43.2,79.24,43.06,79.24Z"/>
                  <path class="cls-2" d="M65.18,28a.21.21,0,0,1-.26,0Z"/>
                  <path class="cls-1" d="M88.49,6.89a11.94,11.94,0,0,1,11.92,11.92V88.49a11.94,11.94,0,0,1-11.92,11.92H18.81A11.94,
                    11.94,0,0,1,6.89,88.49V18.81A11.94,11.94,0,0,1,18.81,6.89H88.49m0-6.89H18.81A18.81,18.81,0,0,0,0,18.81V88.49A18.81,
                    18.81,0,0,0,18.81,107.3H88.49A18.81,18.81,0,0,0,107.3,88.49V18.81A18.81,18.81,0,0,0,88.49,0Z"/>
                </g>
              </g>
            </svg>
            <div>TRAVAUX24</div>
          </a>
        </div>
        <div class="footer-field mt-3">
          <span class="field-label">YouTube</span>

          <a href="https://www.youtube.com/watch?v=9URFQANVQpg" target="_blank"
             class="field-value menu-social facebook-svg link-in-svg">
            <svg viewBox="0 0 48 34">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Social-Icons---Isolated" transform="translate(-164.000000, -510.000000)" fill="#FF0002">
                  <path d="M183.044,533.269916 L183.0425,519.687742 L196.0115,526.502244 L183.044,533.269916
                    Z M211.52,517.334163 C211.52,517.334163 211.0505,514.003199 209.612,512.536366 C207.7865,510.610299
                    205.7405,510.601235 204.803,510.489448 C198.086,510 188.0105,510 188.0105,510 L187.9895,510 C187.9895,
                    510 177.914,510 171.197,510.489448 C170.258,510.601235 168.2135,510.610299 166.3865,512.536366
                    C164.948,514.003199 164.48,517.334163 164.48,517.334163 C164.48,517.334163 164,521.246723
                    164,525.157773 L164,528.82561 C164,532.73817 164.48,536.64922 164.48,536.64922 C164.48,536.64922
                    164.948,539.980184 166.3865,541.447016 C168.2135,543.373084 170.612,543.312658 171.68,543.513574
                     C175.52,543.885191 188,544 188,544 C188,544 198.086,543.984894 204.803,543.495446 C205.7405,543.382148
                      207.7865,543.373084 209.612,541.447016 C211.0505,539.980184 211.52,536.64922 211.52,536.64922
                      C211.52,536.64922 212,532.73817 212,528.82561 L212,525.157773 C212,521.246723 211.52,517.334163
                       211.52,517.334163 L211.52,517.334163 Z" id="Youtube">
                  </path>
                </g>
              </g>
            </svg>
            <div>TRAVAUX24</div>
          </a>
        </div>
      </div>
      <div class="col-12">
        <a class="developer-link" href="https://it.brilliant.ua/" target="_blank">
          <span>Développement de site Web</span>
          <img class="ms-2" src="assets/images/brilliant-logo.svg" alt="brilliant"></a>
      </div>
    </div>
  </div>
</div>
