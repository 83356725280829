import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ScrollToSectionService} from "../../../../services/scrollToSection.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menu = [
    {
      id: "1", 
      name: "À propos", 
      anchor: "#about-company-section" 
    },
    { 
      id: "2", 
      name: "Agences immobilières", 
      anchor: "#estate-agents" 
    },
    { 
      id: "3", 
      name: "Nos services", 
      anchor: "#services-section" 
    },
    { 
      id: "4", 
      name: "Nos réalisations", 
      anchor: "#our-works-section" 
    },
    // { 
    //   id: "5", 
    //   name: "Conseils de TRAVAUX24", 
    //   anchor: "#our-recommendations-section" 
    // },
    { 
      id: "6", 
      name: "Contact", 
      anchor: "#contacts-section" 
    },
  ];

  @Input() background: 'transparent' | 'solid' = 'transparent';
  @Input() noMenu: boolean = false;
  @Input() noSocial: boolean = false;
  @Output() onToggleSideMenu: any = new EventEmitter<any>();

  constructor(private scrollToSectionService: ScrollToSectionService) { }

  ngOnInit(): void {
  }

  handlerSideMenu(): void {
    this.onToggleSideMenu.emit();
  }

  goToSection(elemSelector: string): void {

    this.scrollToSectionService.goToSection(elemSelector);
  }
}
