<div class="modal-wrapper">
  <ng-container *ngIf="data.status === 'success' || data.status === 'fail'">
    <div class="close-modal" mat-dialog-close>
      <img src="assets/images/icons/close-modal.svg" alt="Close">
    </div>

    <div class="message-container">
      <div class="close-modal" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </div>
      <div class="success-wrapper" *ngIf="data.status === 'success'">
        <mat-icon class="success-icon">check_circle</mat-icon>
        <div class="success-message">
          {{ data.message }}
        </div>
      </div>
      <div class="fail-wrapper" *ngIf="data.status === 'fail'">
        <mat-icon class="fail-icon">close</mat-icon>
        <div class="fail-message">
          {{ data.message }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.status === 'rating'">
    <div class="close-model-rating" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>

    <div class="rating-body">
      <mat-icon class="success-icon">done</mat-icon>
      <div class="success-message">
        {{ data.message }}
      </div>
      <div class="message-body">
        {{ data.body }}
      </div>
      <div class="rating-btn">
        <button class="submit-btn g-recaptcha" type="submit" mat-dialog-close>D'ACCORD</button>
      </div>
    </div>

  </ng-container>

</div>


