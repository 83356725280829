<h2 mat-dialog-title class="d-flex justify-content-between">
  <span>{{ data.title }}</span>
  <button (click)="close()" class="close" mat-icon-button tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content >{{ data.message }}</mat-dialog-content>
<mat-dialog-actions class="mt-3 mb-0 p-0 justify-content-end">
  <button (click)="close()" [color]="data.cancelStyle"
          mat-stroked-button>
    <mat-icon *ngIf="data.cancelIcon" matPrefix>
      {{data.cancelIcon}}
    </mat-icon>
    {{ data.cancelText }}
  </button>
  <button (click)="confirm()" [color]="data.confirmStyle"
          mat-stroked-button>
    <mat-icon *ngIf="data.confirmIcon" matPrefix>
      {{data.confirmIcon}}
    </mat-icon>
    {{ data.confirmText }}
  </button>
</mat-dialog-actions>
