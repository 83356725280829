import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menu = [
    {
      id: "1", 
      name: "À Propos de Travaux24", 
      anchor: "#about-company-section" 
    },
    { 
      id: "2", 
      name: "Agences immobilières", 
      anchor: "#estate-agents" 
    },
    { 
      id: "3", 
      name: "Nos services", 
      anchor: "#services-section" 
    },
    { 
      id: "4", 
      name: "Nos réalisations", 
      anchor: "#our-works-section" 
    },
    // { 
    //   id: "5", 
    //   name: "Conseils de TRAVAUX24", 
    //   anchor: "#our-recommendations-section" 
    // },
    { 
      id: "6", 
      name: "Contact", 
      anchor: "#contacts-section" 
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  goToSection(elemSelector: string): void {
    const targetElem = document.querySelector(elemSelector);
    if (targetElem){
      targetElem.scrollIntoView({behavior: 'smooth'});
    }
  }

}
