import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {CookieService} from "ngx-cookie-service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

export interface ISignInData {
  email: string;
  password: string;
  remember_me: boolean
}

export interface ISignInResponse {
  data: IUser,
  message: string,
  success: boolean,
}

export interface IUser {
  expiration?: string;
  token: string;
  name: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject({token: '', name: ''});
  readonly user$ = this._user.asObservable();

  constructor(
    private _cookieService: CookieService,
    private _api: ApiService,
  ) { }

  set accessToken(token: string) {
    this._cookieService.set('access_token', token);
  }
  get accessToken(): string {
    return this._cookieService.get('access_token') || '';
  }

  get user(): IUser {
    const usr = this._user.getValue();
    if (usr.token) {
      return usr
    } else {
      const cookieUser = this._cookieService.get('t24_user');
      return cookieUser ? JSON.parse(cookieUser) : {}
    }
  }
  set user(user: IUser) {
    this._cookieService.set('t24_user', JSON.stringify(user));
    this._user.next(user);
  }

  get isAuthorized(): boolean {
    return !!this.accessToken;
  }

  signIn(data: ISignInData): Observable<any> {
    if(this.accessToken) {
      return of(this.user);
    } else {
      return this._api.post('api/auth/login', data).pipe(
        map((response: ISignInResponse) => {
          if (response && response.success) {
            this.accessToken = response.data.token;
            this.user = response.data;
            return response.data;
          } else {
            return of(response);
          }
        })
      );
    }

  }

}
