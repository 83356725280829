import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  @Input() public src = '';

  constructor(
    private _el: ElementRef,
    private _http: HttpClient,
  ) { }

  ngOnInit(): void {
    this._http.get(this.src, {observe: 'response', responseType: 'text'}).subscribe(svg => {
      // this._el.nativeElement.innerHTML = svg;
    });
  }

}
