import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import {ApiService} from "./api.service";
import {SuccessErrorDialogComponent} from "../modules/shared/components/success-error-dialog/success-error-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  private userData = {
    "email": "site_api@admin.com",
    "password": "site_api@admin.com",
    "remember_me": true
  };

  constructor(
    private matDialog: MatDialog,
    private _auth: AuthService,
    private api: ApiService
  ) {
    this.checkAuth();
  }

  checkAuth(): any{
    if (!this._auth.isAuthorized) {
      this._auth.signIn(this.userData).subscribe()
    }
  }

  getContent(){
    const url = 'api/landing/content';
    return this.api.get(url);
  }

  getNosServices(){
    const url = 'api/landing/services';
    return this.api.get(url);
  }

  getRealizations(){
    const url = 'api/landing/realizations';
    return this.api.get(url);
  }

  getReview(){
    const url = 'api/landing/reviews';
    return this.api.get(url);
  }

  getBrands(){
    const url = 'api/landing/brands';
    return this.api.get(url);
  }

  getNews(){
    const url = 'api/landing/news';
    return this.api.get(url);
  }

  addReviewPost(body: any){
    const url = 'api/landing/reviews/add';
    return this.api.post(url, body);
  }

  addReview(body: any){
    this.api.captcha().subscribe((response) => {
      if (response){
        this.addReviewPost(body).subscribe((addResponse) => {
          if (addResponse){
            this.matDialog.closeAll();

            this.matDialog.open(SuccessErrorDialogComponent, {
              width: '600px',
              data: {
                status: 'rating',
                message: 'Merci pour les commentaires!',
                body: 'Nous le publierons sur le site après vérification par nos modérateurs'
              }
            });
          }
        }, error => {
          console.error('There was an error(addReviewPost)!', error);
        });
      }
    }, error => {
      console.error('There was an error(addReview captcha)!', error);
    });
  }

}
