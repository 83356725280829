<div class="dnd-container" appDnd
     (fileDropped)="onFileDropped($event)"
     *ngIf="!isImageUploader;">
  <input type="file" #fileDropRef id="fileDropRef" multiple
         (change)="fileBrowseHandler($event)" />

  <mat-icon class="dnd-icon">save_alt</mat-icon>

  <p>Cliquez pour joindre ou faites glisser
    et déposez depuis votre ordinateur</p>
  <p>Jusqu'à 20 fichiers, pas plus de 5 Mo chacun</p>
</div>

<div class="files-list" *ngIf="!isImageUploader">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <mat-progress-bar class="upload-progress" mode="determinate" [value]="file?.progress"></mat-progress-bar>
    <div class="file-item">
      <mat-icon class="file-icon">description</mat-icon>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
      </div>
      <button mat-icon-button class="delete-file" (click)="deleteFile(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="uploadError" class="file-error">{{ uploadError }}</div>

  <div *ngIf="files.length && viewBtnUpload" class="d-flex justify-content-center">
    <button mat-stroked-button color="accent" class="mt-2" (click)="handleUpload()" [disabled]="loading">
      <mat-spinner color="accent" class="mx-4 my-1" [diameter]="26" *ngIf="loading; else btnText"></mat-spinner>
      <ng-template #btnText>
        <mat-icon matPrefix class="me-2">cloud_upload</mat-icon> Upload files
      </ng-template>
    </button>
  </div>
</div>
