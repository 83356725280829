import { Component, Input } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { LeaveRequestModalComponent } from "../leave-request-modal/leave-request-modal.component";

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent {
  @Input() isOnDark: boolean = false;
  @Input() isTextBelowSecondBtn: boolean = true;
  @Input() inSideMenu: boolean = false;
  @Input() isNeedQuiz: boolean = false;

  constructor(
    private _matDialog: MatDialog
  ) {}

  openContactForm(): void {
    this._matDialog.open(LeaveRequestModalComponent, {
      width: '600px',
    });
  }
}
