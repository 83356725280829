<div class="input-field-container" [class.focused]="isFocused">
  <div class="input-field-prefix" *ngIf="prefix">{{prefix}}</div>
  <div class="input-field">
    <input
      [type]="type"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      (blur)="$event.stopPropagation(); blur.emit(value); setIsFocused(false)"
      (ngModelChange)="change.emit(value)"
      (keyup.enter)="pressEnter.emit(value)"
      (focus)="setIsFocused(true)" />
  </div>
  <div class="input-field-suffix" *ngIf="suffix">{{suffix}}</div>
</div>
