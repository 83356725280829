import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';


// const AllowedMimeTypesList = [
//   'png', 'jpg', 'jpeg', 'webp', 'svg'
// ] as const;

const AllowedMimeTypesList = [
  'pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'csv',
  'png', 'jpg', 'jpeg', 'webp', 'gif', 'svg',
  'zip', '7z', 'rar'
] as const;

type AllowedMimeTypes = typeof AllowedMimeTypesList[number];

@Component({
  selector: 'app-file-uploader-shared',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  // allowedExt: AllowedMimeTypes[] | '*'  = [...AllowedMimeTypesList];
  @Input() allowedExt: any[] = [...AllowedMimeTypesList];
  @Input() maxFileSize = 5;
  @Output() onFilesUpload: EventEmitter<any> = new EventEmitter<any>();

  isImageUploader: boolean = false;

  maxFiles: number = 5;
  files: any[] = [];

  loading = false;
  uploadError: boolean | string = false;
  viewBtnUpload: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  onFileDropped($event: any): void {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event: any): void {
    this.prepareFilesList(event.target.files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.viewBtnUpload = true;
  }

  handleUpload(): void {
    this.onFilesUpload.emit(this.files);
    // this.files = [];
    this.viewBtnUpload = false;
  }


  private isFileSizeAllowed(file: File): boolean {
    return file.size < this.maxFileSize * 1024 * 1024;
  }

  private isFileExtAllowed(file: File): boolean {
    const fileName = file.name;
    const fileExt = fileName
      .substr(fileName.lastIndexOf('.') + 1, fileName.length)
      .toLowerCase() as AllowedMimeTypes;

    return this.allowedExt.includes(fileExt);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any[]): void {
    for (const item of files) {

      // catch errors
      if (!this.isFileExtAllowed(item)) {
        // @ts-ignore
        this.uploadError = 'Extension de fichier non prise en charge' + '  \"' + item.name + '\"';
      }
      if (!this.isFileSizeAllowed(item)) {
        // @ts-ignore
        this.uploadError = 'Taille de fichier non prise en charge' + '  \"' + item.name + '\"';
      }

      // on success
      if (this.isFileExtAllowed(item) && this.isFileSizeAllowed(item) && this.files.length < this.maxFiles) {
        this.uploadError = false;
        item.progress = 0;
        this.files.push(item);
      }
    }
    if (this.isImageUploader && !this.uploadError) {
      this.handleUpload();
    }
    this.viewBtnUpload = true;
    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   * @returns string (Formatted size string)
   */
  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
