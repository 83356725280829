import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "swiper/angular";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AngularResizeEventModule } from 'angular-resize-event';
import { CookieService } from "ngx-cookie-service";

// Angular Material CDK
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
const materialCdk = [
  DragDropModule,
  PortalModule,
  LayoutModule,
  OverlayModule,
  CdkTreeModule,
];
// END Angular Material CDK

// Angular Material Components
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DateTimePickerModule } from "./date-time-picker/date-time-picker.module";

const materialModules = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  DateTimePickerModule
];
// END Angular Material Components

//Pipes
import { OrderByPipe } from './pipes/order-by.pipe';

// Layout
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { MainLayoutComponent } from "./layout/main-layout/main-layout.component";
import { CalculatorLayoutComponent } from "./layout/calculator-layout/calculator-layout.component";
import { CallToActionComponent } from "./components/call-to-action/call-to-action.component";
import { LeaveRequestModalComponent } from "./components/leave-request-modal/leave-request-modal.component";

// Components
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { SvgComponent } from './components/svg/svg.component';
import { SuccessErrorDialogComponent } from "./components/success-error-dialog/success-error-dialog.component";
import { AlertDialogComponent } from "./components/dialog/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "./components/dialog/confirm-dialog/confirm-dialog.component";
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { SetStartRatingComponent } from './components/set-start-rating/set-start-rating.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainLayoutComponent,
    CalculatorLayoutComponent,
    OrderByPipe,
    ButtonComponent,
    InputComponent,
    SvgComponent,
    CallToActionComponent,
    LeaveRequestModalComponent,
    SuccessErrorDialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    FileUploaderComponent,
    SetStartRatingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ...materialCdk,
    ...materialModules,
    SwiperModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    AngularResizeEventModule,
    AngularSvgIconModule.forRoot(),
  ],
  exports: [
    ...materialCdk,
    ...materialModules,
    SwiperModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    AngularResizeEventModule,
    HeaderComponent,
    FooterComponent,
    MainLayoutComponent,
    CalculatorLayoutComponent,
    OrderByPipe,
    ButtonComponent,
    InputComponent,
    SvgComponent,
    CallToActionComponent,
    LeaveRequestModalComponent,
    SuccessErrorDialogComponent,
    FileUploaderComponent,
    SetStartRatingComponent
  ],
  providers: [
    CookieService,
  ],
})
export class SharedModule { }
