import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor  {
  @Input() val: string | number = "";
  @Input() type: string = "text";
  @Input() name?: string = "";
  @Input() placeholder?: string = "";
  @Input() id?: string = "";
  @Input() prefix?: string = "";
  @Input() suffix?: string = "";
  @Output() change: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() blur: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() pressEnter: EventEmitter<string | number> = new EventEmitter<string | number>();

  private _value: string | number = "";

  isFocused = false;

  constructor() { }

  setIsFocused(focus: boolean): void {
    this.isFocused = focus;
  }

  handleChange(event: Event) {
    if (this._value) {
      this.change.emit(this._value);
    }
  }

  onChange: any = () => {
    this.change.emit(this._value);
  };

  onTouched: any = () => { };

  public get value(){
    return this._value;
  }

  public set value(v){
    this._value = v;
    this.onChange(this._value);
    this.onTouched(this._value);
  }

  writeValue(value: any){
    this.value = value
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
  }

}
