import { Component, OnInit, ViewChild } from '@angular/core';
import {ScrollToSectionService} from "../../../../services/scrollToSection.service";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  menu = [
    {
      id: "1", 
      name: "À propos", 
      anchor: "#about-company-section" 
    },
    { 
      id: "2", 
      name: "Agences immobilières", 
      anchor: "#estate-agents" 
    },
    { 
      id: "3", 
      name: "Nos services", 
      anchor: "#services-section" 
    },
    { 
      id: "4", 
      name: "Nos réalisations", 
      anchor: "#our-works-section" 
    },
    // { 
    //   id: "5", 
    //   name: "Conseils de TRAVAUX24", 
    //   anchor: "#our-recommendations-section" 
    // },
    { 
      id: "6", 
      name: "Contact", 
      anchor: "#contacts-section" 
    },
  ];

  @ViewChild('drawer') drawer!: any;

  constructor(private scrollToSectionService: ScrollToSectionService) { }

  ngOnInit(): void {
  }

  goToSection(elemSelector: string): void {
    this.drawer.close();

    this.scrollToSectionService.goToSection(elemSelector);
  }
}
