import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EModalResult } from '../dialog-enums';
import { IConfirmData } from '../dialog-interface';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public readonly matDialogRef: MatDialogRef<ConfirmDialogComponent, EModalResult>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IConfirmData
  ) {
  }

  ngOnInit(): void {
  }

  public confirm(): void {
    this.matDialogRef.close(EModalResult.Confirm);
  }

  public close(): void {
    this.matDialogRef.close(EModalResult.Cancel);
  }

}
