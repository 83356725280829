import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

interface DialogData {
  status: 'success'|'fail'|'rating';
  message: string;
  body?: string;
}

@Component({
  selector: 'app-success-error-dialog',
  templateUrl: './success-error-dialog.component.html',
  styleUrls: ['./success-error-dialog.component.scss']
})
export class SuccessErrorDialogComponent implements OnInit{

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }
}
