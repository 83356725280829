import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: Array<any> | null | undefined, orderField: string): Array<any> {
    if(array && array.length > 0) {
      array.sort((a: any, b: any) => {
        if (a[orderField] < b[orderField]) {
          return -1;
        } else if (a[orderField] > b[orderField]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array || [];
  }

}
