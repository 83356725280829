import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToSectionService {
  goToSection(elemSelector: string): void {

    const targetElem = document.querySelector(elemSelector);
    if (targetElem){
      targetElem.scrollIntoView({behavior: 'smooth'});
    }
  }
}
