import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-set-start-rating',
  templateUrl: './set-start-rating.component.html',
  styleUrls: ['./set-start-rating.component.scss']
})
export class SetStartRatingComponent implements OnInit {
  @Output() ratingUpdated = new EventEmitter();

  rating: number = 0;
  starCount: number = 5;
  ratingArr: any = [];

  constructor() { }

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  onChangedRating(rating:number) {
    this.rating = rating;
    this.ratingUpdated.emit(rating);

    return false;
  }

  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
