<div class="text-center">
  <div [ngClass]="{'buttons-grid': !inSideMenu, 'btns-in-side-menu' : inSideMenu}">
    <div class="btn-wrap">
      <button class="btn text-uppercase"
              [ngClass]="{'btn-outline-black' : !isOnDark, 'btn-outline-white': isOnDark}"
              (click)="openContactForm()">
        Contactez-nous ou demandez un devis gratuit
      </button>
    </div>

    <div class="btn-wrap">
      <a [href]="isNeedQuiz ? '#showquiz' : '/estimation'"
         class="btn btn-solid text-uppercase">
        {{ isNeedQuiz ?
          'Estimez votre projet en quelques clics' :
          'Estimez votre projet en détails'
        }}
      </a>
    </div>
  </div>
</div>
