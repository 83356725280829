<div class="close-modal" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>

<div class="modal-wrapper">

  <form class="form-wrap" [formGroup]="leaveRequestModalForm" (ngSubmit)="checkCaptchaAndSubmit()">

    <div class="title">Laisser une demande de calcul des coûts</div>

    <div class="row">
      <div class="col-12">
        <div class="form-field" [ngStyle]="{'margin-bottom': showError('name') ? '4px' : '30px'}">
          <input
            placeholder="Nom *"
            formControlName="name"
            type="text">
          <div class="form-field-error" *ngIf="showError('name')">{{getErrorMessage('name')}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-field" [ngStyle]="{'margin-bottom': showError('email') ? '4px' : '30px'}">
          <input
            placeholder="Email *"
            formControlName="email"
            type="email">
          <div class="form-field-error" *ngIf="showError('email')">{{getErrorMessage('email')}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-field" [ngStyle]="{'margin-bottom': showError('phone') ? '4px' : '30px'}">
          <input
            placeholder="Numéro de téléphone *"
            formControlName="phone"
            type="phone">
          <div class="form-field-error" *ngIf="showError('phone')">{{getErrorMessage('phone')}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-field" style="margin-bottom: 30px">
        <textarea class="w-100"
                  placeholder="Décrivez votre projet en quelques mots"
                  formControlName="text"
                  rows="4"
        ></textarea>
        </div>
      </div>


      <div class="col-12">
        <div class="d-flex justify-content-center w-100">
          <button class="submit-btn g-recaptcha"
                  type="submit"
                  [disabled]="leaveRequestModalForm.invalid || submitInProgress"
                  [ngClass]="{'submit-disabled': leaveRequestModalForm.invalid}">
            ENVOYER
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
