import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EModalResult } from '../dialog-enums';
import { IAlertData } from '../dialog-interface';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  constructor(
    public readonly matDialogRef: MatDialogRef<AlertDialogComponent, EModalResult>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IAlertData
  ) {
  }

  ngOnInit(): void {
  }

  public confirm(): void {
    this.matDialogRef.close(EModalResult.Confirm);
  }

  public close(): void {
    this.matDialogRef.close(EModalResult.Cancel);
  }
}
