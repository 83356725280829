import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import {IAlertData, IConfirmData} from "../modules/shared/components/dialog/dialog-interface";
import {EModalResult} from "../modules/shared/components/dialog/dialog-enums";
import {ConfirmDialogComponent} from "../modules/shared/components/dialog/confirm-dialog/confirm-dialog.component";
import {AlertDialogComponent} from "../modules/shared/components/dialog/alert-dialog/alert-dialog.component";


/**
 * Returns object without properties numbered in second parameter
 * @param obj - Object to be modefied
 * @param keys = String or array of strings with property names to be removed
 * @returns Object - Object without properties
 */
function omit(obj: object, ...keys: Array<string | string[]>): object {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  return Object.fromEntries( // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)) // remove entries with keys that exist in the Set
  );
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  defaultConfirmData: IConfirmData = {
    message: '',
    title: '',
    confirmText: 'Ok',
    confirmIcon: '',
    confirmStyle: 'primary',
    cancelText: 'Cancel',
    cancelIcon: '',
    cancelStyle: 'warn'
  };
  defaultAlertData: IAlertData = {
    message: '',
    title: '',
    confirmText: 'Ok',
    confirmIcon: '',
    confirmStyle: 'primary',
  };

  constructor(
    private dialog: MatDialog
  ) {}

  /**
   * Opens Confirm modal
   * @param data: The required parameter id { message: string }
   * @returns Promise the result of confirm action
   */
  public confirm(data: IConfirmData): Promise<EModalResult> {
    const newData = {
      ...this.defaultConfirmData,
      ...omit(data, ['width', 'disableClose'])
    };
    return this.dialog.open(ConfirmDialogComponent, {
      data: newData,
      width: data.width || '448px',
      disableClose: data.disableClose || false,
    }).afterClosed().toPromise();
  }

  /**
   * Opens Alert modal
   * @param data The required parameter id { message: string }
   * @returns Promise the result of closing alert modal
   */
  public alert(data: IAlertData): Promise<EModalResult> {
    const newData = {
      ...this.defaultAlertData,
      ...omit(data, ['width', 'disableClose'])
    };
    return this.dialog.open(AlertDialogComponent, {
      data: newData,
      width: data.width || '448px',
      disableClose: data.disableClose || false,
    }).afterClosed().toPromise();
  }

}
