import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() value: string | number = "";
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: 'solid' | 'outline' | 'outline-transparent' = "solid";
  @Input() color: 'blue' | 'dark' | 'white' = "blue";
  @Input() rounded: boolean = true;
  @Output() click: EventEmitter<string | number> = new EventEmitter<string | number>();


  handleClick(event: Event) {
    event.stopPropagation();
    this.click.emit(this.value);
  }

}
