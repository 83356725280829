import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import { LandingService } from "../../../../services/landing.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { SuccessErrorDialogComponent } from "../success-error-dialog/success-error-dialog.component";
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-leave-request-modal',
  templateUrl: './leave-request-modal.component.html',
  styleUrls: ['./leave-request-modal.component.scss']
})
export class LeaveRequestModalComponent implements OnInit, OnDestroy{
  @ViewChild('recaptcha') recaptcha: ElementRef;

  private apiURL = environment.baseUrl + 'api';
  leaveRequestModalForm: FormGroup;

  submitInProgress = false;

  constructor(
    private _fb: FormBuilder,
    private _landingService: LandingService,
    private _http: HttpClient,
    private _matDialog: MatDialog,
    private _cookie: CookieService,
    private _recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.leaveRequestModalForm = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      text: ['']
    })
  }

  ngOnInit(): void {

  }
  ngOnDestroy() {

  }

  public showError(fieldName: string): boolean {
    const field = this.getField(fieldName);
    return field.invalid && field.dirty;
  }

  public getField(fieldName: string): AbstractControl {
    return this.leaveRequestModalForm.controls[fieldName];
  }

  getErrorMessage(control: string): string {
    const errors = this.leaveRequestModalForm.controls[control].errors || {};
    if ('required' in errors) {
      return 'Ce champ est requis';
    }
    else if ('email' in errors) {
      return "L'email ñ'est pas valide";
    }

    return '';
  }

  checkCaptchaAndSubmit(): void { //check recaptcha and then submit the form
    this.submitInProgress = true;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._cookie.get('access_token'),
    };

    this._recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {

        this._http.post(environment.baseUrl + 'api/check-recaptcha', {token}, {headers}).subscribe(
          result => {
            if (result === true){
              this.submitForm();
            } else {
              this._matDialog.open(SuccessErrorDialogComponent, {
                width: '600px',
                data: {
                  status: 'fail',
                  message: 'Oups. Quelque chose s\'est mal passé...'
                }
              });
              this.submitInProgress = false;
            }
          }
        );
      });
  }

  submitForm(): void{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._cookie.get('access_token')
    };
    const body = {
      ...this.leaveRequestModalForm.value,
      'form_id': 1
    };
    // @ts-ignore
    this._http.post<any>(this.apiURL + '/process/form', body, {headers}).subscribe(
      res => {
        this._matDialog.open(SuccessErrorDialogComponent, {
          width: '600px',
          data: {
            status: 'success',
            message: 'Le message a été envoyé avec succès'
          }
        });
        this.submitInProgress = false;
      },
      err => {
        this._landingService.checkAuth();
        this._matDialog.open(SuccessErrorDialogComponent, {
          width: '600px',
          data: {
            status: 'fail',
            message: 'Oups. Quelque chose s\'est mal passé...'
          }
        });
        this.submitInProgress = false;
      },
    );
  }
}
