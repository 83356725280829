<button
  class="btn"
  [ngClass]="[type, color]"
  [class.rounded]="rounded"
  [class.active]="active"
  [class.disabled]="disabled"
  [disabled]="disabled"
  (click)="handleClick($event)"
>
  <span class="text">
    <ng-content></ng-content>
  </span>
</button>
