<button mat-icon-button
        *ngFor="let ratingId of ratingArr;index as i"
        [id]="'star_'+i"
        (click)="onChangedRating(i+1)">
  <mat-icon>
    {{showIcon(i)}}
  </mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
